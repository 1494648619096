
export const organizationTypes = [
    {
        id: 'corporate',
        label: 'Corporate',
    },
    {
        id: 'family',
        label: 'Family',
    },
    {
        id: 'ngo',
        label: 'NGO',
    },
    {
        id: 'alumni',
        label: 'Alumni',
    },
    {
        id: 'beneficiary',
        label: 'Beneficiary',
    },
    // {
    //     id: 'visitor',
    //     label: 'VISITOR',
    // },
    // {
    //     id: 'onsite_staff',
    //     label: 'ONSITE STAFF',
    // },
    // {
    //     id: 'donors',
    //     label: 'DONORS',
    // },
]