const userTreeActionTypes = {
    GET_USER_TREES_REQUESTED: "GET_USER_TREES_REQUESTED",
    GET_USER_TREES_SUCCEEDED: "GET_USER_TREES_SUCCEEDED",
    GET_USER_TREES_FAILED: "GET_USER_TREES_FAILED",

    CREATE_USER_TREE_REQUESTED: "CREATE_USER_TREE_REQUESTED",
    CREATE_USER_TREE_SUCCEEDED: "CREATE_USER_TREE_SUCCEEDED",
    CREATE_USER_TREE_FAILED: "CREATE_USER_TREE_FAILED",

    UPDATE_USER_TREE_REQUESTED: "UPDATE_USER_TREE_REQUESTED",
    UPDATE_USER_TREE_SUCCEEDED: "UPDATE_USER_TREE_SUCCEEDED",
    UPDATE_USER_TREE_FAILED: "UPDATE_USER_TREE_FAILED",

    UNASSIGN_USER_TREES_REQUESTED: "UNASSIGN_USER_TREES_REQUESTED",
    UNASSIGN_USER_TREES_SUCCEEDED: "UNASSIGN_USER_TREES_SUCCEEDED",
    UNASSIGN_USER_TREES_FAILED: "UNASSIGN_USER_TREES_FAILED",

    ASSIGN_USER_TREES_REQUESTED: "ASSIGN_USER_TREES_REQUESTED",
    ASSIGN_USER_TREES_SUCCEEDED: "ASSIGN_USER_TREES_SUCCEEDED",
    ASSIGN_USER_TREES_FAILED: "ASSIGN_USER_TREES_FAILED",

    GET_USER_TREE_COUNT_REQUESTED: "GET_USER_TREE_COUNT_REQUESTED",
    GET_USER_TREE_COUNT_SUCCEEDED: "GET_USER_TREE_COUNT_SUCCEEDED",
    GET_USER_TREE_COUNT_FAILED: "GET_USER_TREE_COUNT_FAILED",

    MAP_USER_TREES_IN_PLOT_REQUESTED: "MAP_USER_TREES_IN_PLOT_REQUESTED",
    MAP_USER_TREES_IN_PLOT_SUCCEEDED: "MAP_USER_TREES_IN_PLOT_SUCCEEDED",
    MAP_USER_TREES_IN_PLOT_FAILED: "MAP_USER_TREES_IN_PLOT_FAILED",

    MAP_USER_TREES_REQUESTED: "MAP_USER_TREES_REQUESTED",
    MAP_USER_TREES_SUCCEEDED: "MAP_USER_TREES_SUCCEEDED",
    MAP_USER_TREES_FAILED: "MAP_USER_TREES_FAILED",

    UN_MAP_TREES_REQUESTED: "UN_MAP_TREES_REQUESTED",
    UN_MAP_TREES_SUCCEEDED: "UN_MAP_TREES_SUCCEEDED",
    UN_MAP_TREES_FAILED: "UN_MAP_TREES_FAILED",
};

export default userTreeActionTypes;