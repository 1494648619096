 
 
 
 
const eventActionTypes = {
  GET_EVENTS_REQUESTED: "GET_EVENTS_REQUESTED",
  GET_EVENTS_SUCCEEDED: "GET_EVENTS_SUCCEEDED",
  GET_EVENTS_FAILED: "GET_EVENTS_FAILED",


  DELETE_EVENTS_REQUESTED: "DELETE_EVENTS_REQUESTED",
  DELETE_EVENTS_SUCCEEDED: "DELETE_EVENTS_SUCCEEDED",
  DELETE_EVENTS_FAILED: "DELETE_EVENTS_FAILED",

  UPDATE_EVENTS_REQUESTED: "UPDATE_EVENTS_REQUESTED",
  UPDATE_EVENTS_SUCCEEDED: "UPDATE_EVENTS_SUCCEEDED",
  UPDATE_EVENTS_FAILED: "UPDATE_EVENTS_FAILED",

  
};

export default eventActionTypes;