const plantTypeActionTypes = {
    GET_PLANT_TYPES_REQUESTED: "GET_PLANT_TYPES_REQUESTED",
    GET_PLANT_TYPES_SUCCEEDED: "GET_PLANT_TYPES_SUCCEEDED",
    GET_PLANT_TYPES_FAILED: "GET_PLANT_TYPES_FAILED",

    SEARCH_PLANT_TYPES_REQUESTED: "SEARCH_PLANT_TYPES_REQUESTED",
    SEARCH_PLANT_TYPES_SUCCEEDED: "SEARCH_PLANT_TYPES_SUCCEEDED",
    SEARCH_PLANT_TYPES_FAILED: "SEARCH_PLANT_TYPES_FAILED",

    CREATE_PLANT_TYPE_REQUESTED: "CREATE_PLANT_TYPE_REQUESTED",
    CREATE_PLANT_TYPE_SUCCEEDED: "CREATE_PLANT_TYPE_SUCCEEDED",
    CREATE_PLANT_TYPE_FAILED: "CREATE_PLANT_TYPE_FAILED",

    UPDATE_PLANT_TYPE_REQUESTED: "UPDATE_PLANT_TYPE_REQUESTED",
    UPDATE_PLANT_TYPE_SUCCEEDED: "UPDATE_PLANT_TYPE_SUCCEEDED",
    UPDATE_PLANT_TYPE_FAILED: "UPDATE_PLANT_TYPE_FAILED",

    DELETE_PLANT_TYPE_REQUESTED: "DELETE_PLANT_TYPE_REQUESTED",
    DELETE_PLANT_TYPE_SUCCEEDED: "DELETE_PLANT_TYPE_SUCCEEDED",
    DELETE_PLANT_TYPE_FAILED: "DELETE_PLANT_TYPE_FAILED",
};

export default plantTypeActionTypes;