const siteActionTypes = {
    GET_SITES_REQUESTED: "GET_SITES_REQUESTED",
    GET_SITES_SUCCEEDED: "GET_SITES_SUCCEEDED",
    GET_SITES_FAILED: "GET_SITES_FAILED",

    CREATE_SITE_REQUESTED: "CREATE_SITE_REQUESTED",
    CREATE_SITE_SUCCEEDED: "CREATE_SITE_SUCCEEDED",
    CREATE_SITE_FAILED: "CREATE_SITE_FAILED",

    UPDATE_SITE_REQUESTED: "UPDATE_SITE_REQUESTED",
    UPDATE_SITE_SUCCEEDED: "UPDATE_SITE_SUCCEEDED",
    UPDATE_SITE_FAILED: "UPDATE_SITE_FAILED",

    DELETE_SITE_REQUESTED: "DELETE_SITE_REQUESTED",
    DELETE_SITE_SUCCEEDED: "DELETE_SITE_SUCCEEDED",
    DELETE_SITE_FAILED: "DELETE_SITE_FAILED",

    SYNC_SITES_REQUESTED: "SYNC_SITES_REQUESTED",
    SYNC_SITES_SUCCEEDED: "SYNC_SITES_SUCCEEDED",
    SYNC_SITES_FAILED: "SYNC_SITES_FAILED",
};

export default siteActionTypes;