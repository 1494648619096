const giftCardActionTypes = {
    GET_GIFT_CARDS_REQUESTED: "GET_GIFT_CARDS_REQUESTED",
    GET_GIFT_CARDS_SUCCEEDED: "GET_GIFT_CARDS_SUCCEEDED",
    GET_GIFT_CARDS_FAILED: "GET_GIFT_CARDS_FAILED",

    CREATE_GIFT_CARD_REQUESTED: "CREATE_GIFT_CARD_REQUESTED",
    CREATE_GIFT_CARD_SUCCEEDED: "CREATE_GIFT_CARD_SUCCEEDED",
    CREATE_GIFT_CARD_FAILED: "CREATE_GIFT_CARD_FAILED",

    UPDATE_GIFT_CARD_REQUESTED: "UPDATE_GIFT_CARD_REQUESTED",
    UPDATE_GIFT_CARD_SUCCEEDED: "UPDATE_GIFT_CARD_SUCCEEDED",
    UPDATE_GIFT_CARD_FAILED: "UPDATE_GIFT_CARD_FAILED",

    DELETE_GIFT_CARD_REQUESTED: "DELETE_GIFT_CARD_REQUESTED",
    DELETE_GIFT_CARD_SUCCEEDED: "DELETE_GIFT_CARD_SUCCEEDED",
    DELETE_GIFT_CARD_FAILED: "DELETE_GIFT_CARD_FAILED",

    GET_BOOKED_GIFT_CARDS_REQUESTED: "GET_BOOKED_GIFT_CARDS_REQUESTED",
    GET_BOOKED_GIFT_CARDS_SUCCEEDED: "GET_BOOKED_GIFT_CARDS_SUCCEEDED",
    GET_BOOKED_GIFT_CARDS_FAILED: "GET_BOOKED_GIFT_CARDS_FAILED",

    REDEEM_GIFT_CARD_REQUESTED: "REDEEM_GIFT_CARD_REQUESTED",
    REDEEM_GIFT_CARD_SUCCEEDED: "REDEEM_GIFT_CARD_SUCCEEDED",
    REDEEM_GIFT_CARD_FAILED: "REDEEM_GIFT_CARD_FAILED",
};

export default giftCardActionTypes;