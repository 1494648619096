const tagActionTypes = {
    GET_TAGS_REQUESTED: "GET_TAGS_REQUESTED",
    GET_TAGS_SUCCEEDED: "GET_TAGS_SUCCEEDED",
    GET_TAGS_FAILED: "GET_TAGS_FAILED",

    CREATE_TAGS_REQUESTED: "CREATE_TAGS_REQUESTED",
    CREATE_TAGS_SUCCEEDED: "CREATE_TAGS_SUCCEEDED",
    CREATE_TAGS_FAILED: "CREATE_TAGS_FAILED",
};

export default tagActionTypes;