
const onsiteStaffActionTypes = {
    GET_ONSITE_STAFFS_REQUESTED: "GET_ONSITE_STAFFS_REQUESTED",
    GET_ONSITE_STAFFS_SUCCEEDED: "GET_ONSITE_STAFFS_SUCCEEDED",
    GET_ONSITE_STAFFS_FAILED: "GET_ONSITE_STAFFS_FAILED",

    CREATE_ONSITE_STAFF_REQUESTED: "CREATE_ONSITE_STAFF_REQUESTED",
    CREATE_ONSITE_STAFF_SUCCEEDED: "CREATE_ONSITE_STAFF_SUCCEEDED",
    CREATE_ONSITE_STAFF_FAILED: "CREATE_ONSITE_STAFF_FAILED",

    UPDATE_ONSITE_STAFF_REQUESTED: "UPDATE_ONSITE_STAFF_REQUESTED",
    UPDATE_ONSITE_STAFF_SUCCEEDED: "UPDATE_ONSITE_STAFF_SUCCEEDED",
    UPDATE_ONSITE_STAFF_FAILED: "UPDATE_ONSITE_STAFF_FAILED",

    DELETE_ONSITE_STAFF_REQUESTED: "DELETE_ONSITE_STAFF_REQUESTED",
    DELETE_ONSITE_STAFF_SUCCEEDED: "DELETE_ONSITE_STAFF_SUCCEEDED",
    DELETE_ONSITE_STAFF_FAILED: "DELETE_ONSITE_STAFF_FAILED",
};

export default onsiteStaffActionTypes;