const groupActionTypes = {
    GET_GROUPS_REQUESTED: "GET_GROUPS_REQUESTED",
    GET_GROUPS_SUCCEEDED: "GET_GROUPS_SUCCEEDED",
    GET_GROUPS_FAILED: "GET_GROUPS_FAILED",

    CREATE_GROUP_REQUESTED: "CREATE_GROUP_REQUESTED",
    CREATE_GROUP_SUCCEEDED: "CREATE_GROUP_SUCCEEDED",
    CREATE_GROUP_FAILED: "CREATE_GROUP_FAILED",

    UPDATE_GROUP_REQUESTED: "UPDATE_GROUP_REQUESTED",
    UPDATE_GROUP_SUCCEEDED: "UPDATE_GROUP_SUCCEEDED",
    UPDATE_GROUP_FAILED: "UPDATE_GROUP_FAILED",

    DELETE_GROUP_REQUESTED: "DELETE_GROUP_REQUESTED",
    DELETE_GROUP_SUCCEEDED: "DELETE_GROUP_SUCCEEDED",
    DELETE_GROUP_FAILED: "DELETE_GROUP_FAILED",

    SEARCH_GROUPS_REQUESTED: "SEARCH_GROUPS_REQUESTED",
    SEARCH_GROUPS_SUCCEEDED: "SEARCH_GROUPS_SUCCEEDED",
    SEARCH_GROUPS_FAILED: "SEARCH_GROUPS_FAILED",
};

export default groupActionTypes;