const pondActionTypes = {
    GET_PONDS_REQUESTED: "GET_PONDS_REQUESTED",
    GET_PONDS_SUCCEEDED: "GET_PONDS_SUCCEEDED",
    GET_PONDS_FAILED: "GET_PONDS_FAILED",

    SEARCH_PONDS_REQUESTED: "SEARCH_PONDS_REQUESTED",
    SEARCH_PONDS_SUCCEEDED: "SEARCH_PONDS_SUCCEEDED",
    SEARCH_PONDS_FAILED: "SEARCH_PONDS_FAILED",

    CREATE_POND_REQUESTED: "CREATE_POND_REQUESTED",
    CREATE_POND_SUCCEEDED: "CREATE_POND_SUCCEEDED",
    CREATE_POND_FAILED: "CREATE_POND_FAILED",

    UPDATE_POND_REQUESTED: "UPDATE_POND_REQUESTED",
    UPDATE_POND_SUCCEEDED: "UPDATE_POND_SUCCEEDED",
    UPDATE_POND_FAILED: "UPDATE_POND_FAILED",

    DELETE_POND_REQUESTED: "DELETE_POND_REQUESTED",
    DELETE_POND_SUCCEEDED: "DELETE_POND_SUCCEEDED",
    DELETE_POND_FAILED: "DELETE_POND_FAILED",

    GET_POND_WATER_LEVEL_UPDATES_REQUESTED: "GET_POND_WATER_LEVEL_UPDATES_REQUESTED",
    GET_POND_WATER_LEVEL_UPDATES_SUCCEEDED: "GET_POND_WATER_LEVEL_UPDATES_SUCCEEDED",
    GET_POND_WATER_LEVEL_UPDATES_FAILED: "GET_POND_WATER_LEVEL_UPDATES_FAILED",

    CREATE_POND_WATER_LEVEL_UPDATE_REQUESTED: "CREATE_POND_WATER_LEVEL_UPDATE_REQUESTED",
    CREATE_POND_WATER_LEVEL_UPDATE_SUCCEEDED: "CREATE_POND_WATER_LEVEL_UPDATE_SUCCEEDED",
    CREATE_POND_WATER_LEVEL_UPDATE_FAILED: "CREATE_POND_WATER_LEVEL_UPDATE_FAILED",

    UPDATE_POND_WATER_LEVEL_UPDATE_REQUESTED: "UPDATE_POND_WATER_LEVEL_UPDATE_REQUESTED",
    UPDATE_POND_WATER_LEVEL_UPDATE_SUCCEEDED: "UPDATE_POND_WATER_LEVEL_UPDATE_SUCCEEDED",
    UPDATE_POND_WATER_LEVEL_UPDATE_FAILED: "UPDATE_POND_WATER_LEVEL_UPDATE_FAILED",

    DELETE_POND_WATER_LEVEL_UPDATE_REQUESTED: "DELETE_POND_WATER_LEVEL_UPDATE_REQUESTED",
    DELETE_POND_WATER_LEVEL_UPDATE_SUCCEEDED: "DELETE_POND_WATER_LEVEL_UPDATE_SUCCEEDED",
    DELETE_POND_WATER_LEVEL_UPDATE_FAILED: "DELETE_POND_WATER_LEVEL_UPDATE_FAILED",
};

export default pondActionTypes;