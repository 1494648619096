import { FC, useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import ApiClient from "../../../../api/apiClient/apiClient";

const EventTypes = [
    {
        value: '1',
        label: 'Birthday'
    },
    {
        value: '2',
        label: 'Memorial'
    },
    {
        value: '3',
        label: 'General gift'
    },
]

const defaultMessages = {
    primary: 'We are immensely delighted to share that a tree has been planted in your name at the 14 Trees Foundation, Pune. This tree will be nurtured in your honour, rejuvenating ecosystems, supporting biodiversity, and helping offset the harmful effects of climate change.',
    memorial: 'A tree has been planted in the memory of <name here> at the 14 Trees Foundation reforestation site. For many years, this tree will help rejuvenate local ecosystems, support local biodiversity and offset the harmful effects of climate change and global warming.',
    secondary: 'We invite you to visit 14 Trees and firsthand experience the growth and contribution of your tree towards a greener future.',
    logo: 'Gifted by 14 Trees in partnership with'
}

interface Massages {
    primaryMessage: string,
    secondaryMessage: string,
    eventName: string,
    eventType: string | undefined,
    plantedBy: string,
    logoMessage: string,
}

interface CardDetailsProps {
    logo_url?: string | null,
    request_id: string,
    presentationId: string | null,
    slideId: string | null,
    messages: Massages,
    onChange: (messages: Massages) => void
    onPresentationId: (presentationId: string, slideId: string) => void
}

const CardDetails: FC<CardDetailsProps> = ({ logo_url, request_id, presentationId, slideId, messages, onChange, onPresentationId }) => {

    const [selectedEventType, setSelectedEventType] = useState<{ value: string, label: string } | null>(null);

    const slideIdRef = useRef('');
    const presentationIdIdRef = useRef('');
    const recordRef = useRef({ primary: '', secondary: '', logo: '' })
    const logoRef = useRef({ logoUrl: undefined as string | null | undefined })
    const [iframeSrc, setIframeSrc] = useState<string | null>(null);

    const updateSlide = async () => {
        if (!slideIdRef.current || !presentationIdIdRef.current) {
            return;
        }

        const apiClient = new ApiClient();
        await apiClient.updateGiftCardTemplate(slideIdRef.current, recordRef.current.primary, recordRef.current.secondary, recordRef.current.logo, logo_url);
        setIframeSrc(
            `https://docs.google.com/presentation/d/${presentationIdIdRef.current}/embed?rm=minimal&slide=id.${slideIdRef.current}&timestamp=${new Date().getTime()}`
        );
    }

    useEffect(() => {
        const generateGiftCard = async () => {
            const apiClient = new ApiClient();
            const resp = await apiClient.generateCardTemplate(request_id, defaultMessages.primary, defaultMessages.secondary, defaultMessages.logo, logo_url);
            slideIdRef.current = resp.slide_id;
            presentationIdIdRef.current = resp.presentation_id;

            onPresentationId(resp.presentation_id, resp.slide_id);
            setIframeSrc(
                `https://docs.google.com/presentation/d/${resp.presentation_id}/embed?rm=minimal&slide=id.${resp.slide_id}&timestamp=${new Date().getTime()}`
            )
        }

        const handler = setTimeout(() => {
            if (!presentationId || !slideId) generateGiftCard();
            else {
                presentationIdIdRef.current = presentationId;
                slideIdRef.current = slideId

                setIframeSrc(
                    `https://docs.google.com/presentation/d/${presentationId}/embed?rm=minimal&slide=id.${slideId}&timestamp=${new Date().getTime()}`
                )
            }
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [presentationId, slideId])

    useEffect(() => {
        logoRef.current.logoUrl = logo_url
    }, [logo_url])

    useEffect(() => {
        const eventType = EventTypes.find(item => item.value === messages.eventType)
        setSelectedEventType(eventType ? eventType : null);

        if (messages.primaryMessage === "" || messages.secondaryMessage === "" || messages.logoMessage === "") {
            onChange({
                ...messages,
                primaryMessage: eventType?.value === "2" ? defaultMessages.memorial : defaultMessages.primary,
                secondaryMessage: defaultMessages.secondary,
                logoMessage: defaultMessages.logo,
            })
        }
    }, [messages])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name: field, value }= e.target

        onChange({
            ...messages,
            [field]: value,
        })
    }

    const handleEventTypeSelection = (e: any, item: { value: string, label: string } | null) => {
        onChange({
            ...messages,
            eventType: item ? item.value : undefined,
            primaryMessage: messages.primaryMessage === defaultMessages.primary && item?.value === "2" ? defaultMessages.memorial : messages.primaryMessage, 
        })
    }

    return (
        <div style={{ display: 'flex', padding: '10px 10px', width: '100%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '42%' }}>
                <Typography variant='h6'>Please provide the messages to use on gift card: </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>Primary Message (2)</Typography>
                <TextField
                    multiline
                    name="primaryMessage"
                    value={messages.primaryMessage}
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 270 }}
                />
                <Typography variant="body1" sx={{ mt: 2 }}>Secondary Message (3)</Typography>
                <TextField
                    multiline
                    name="secondaryMessage"
                    value={messages.secondaryMessage}
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 125 }}
                />
                <Typography variant="body1" sx={{ mt: 2 }}>Event Type</Typography>
                <Autocomplete
                    size="small"
                    value={selectedEventType}
                    options={EventTypes}
                    getOptionLabel={option => option.label}
                    onChange={handleEventTypeSelection}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="eventType"    
                            margin='dense'
                            label='Event Type'
                        />
                    )}
                />
                <Typography variant="body1" sx={{ mt: 2 }}>Event Name</Typography>
                <TextField
                    name="eventName"
                    value={messages.eventName}
                    onChange={handleChange}
                    size="small"
                />
                <Typography variant="body1" sx={{ mt: 2 }}>Gifted By</Typography>
                <TextField
                    name="plantedBy"
                    value={messages.plantedBy}
                    onChange={handleChange}
                    size="small"
                />
                <Typography variant="body1" sx={{ mt: 2 }}>Logo Message (4)</Typography>
                <TextField
                    name="logoMessage"
                    value={messages.logoMessage}
                    onChange={handleChange}
                    size="small"
                    inputProps={{ maxLength: 50 }}
                />
                <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 20 }}>
                    <Button onClick={updateSlide} variant="contained" color="success" disabled={!presentationIdIdRef.current}>
                        Update
                    </Button>
                </Box>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '55%' }}>
                {/* <img src={cardImage} /> */}
                {iframeSrc && <iframe
                    src={iframeSrc}
                    width="800"
                    height="600"
                    allowFullScreen
                ></iframe>}
            </div>
        </div>
    )
}

export default CardDetails;